import { ReactElement } from 'react';
import '@assets/styles/pages/login-page.scss';
import { Navigate } from 'react-router-dom';
import { useRouteHandler } from '../authGuard';

export const LoginPage = (): ReactElement => {
	const { isAuthenticated } = useRouteHandler();

	if (!isAuthenticated()) {
		window.location.href = process.env.REACT_APP_FV_REDIRECTION_URL ?? '/';
		return <></>;
	}

	return <Navigate to="/" replace />;
};
